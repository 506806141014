<template>
    <div v-if="trip && translates && translates[langUrl]" class="view-trip-configurator">
        <v-tabs v-model="tabs.current">
            <v-tabs-slider />
            <v-tab
                v-for="item in tabs.items"
                :id="item"
                :key="item"
                :href="'#step_' + item"
                @click.stop="goTo(item)"
                :class="landing? 'landing' : ''"
                >
                <template v-if="validates['step_' + item] === true"><div class="validate-check validate-true"></div></template>
                <template v-else-if="validates['step_' + item] === false"><div class="validate-check validate-false"></div></template>
                <template v-else><div class="validate-check"></div></template>
            </v-tab>
        </v-tabs>
        <div class="view-trip-configurator__tabs-outline"></div>
        <div class="view-trip-configurator__main-container">
            <v-tabs-items v-model="tabs.current" class="view-trip-configurator__main-container__items">
                <v-tab-item value="step_1">
                    <ValidationObserver ref="validator_1" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_mainInfo[lang] }}</h1>
                        </div>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_tripType[lang] }}</div>
                                <BaseSelect
                                    :value="trip.type"
                                    :items="typesSelect"
                                    item-text="title"
                                    item-value="value"
                                    :label="translates[langUrl].fieldTitle_tripType[lang]"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1: 'type', value: $event })" />
                            </div> 
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:100">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_tripName[lang] }}</div>
                                <BaseTextField
                                    :value="trip.name"
                                    :label="translates[langUrl].fieldTitle_tripName[lang]"
                                    :error-messages="errors[0]"
                                    :counter="60"
                                    @input="setTripValue({ prop_1: 'name', value: $event })" />
                            </div> 
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_direction[lang] }}</div>
                                <BaseSelect
                                    :value="trip.directionType"
                                    :items="directionTypesSelect"
                                    item-text="title"
                                    item-value="value"
                                    :label="translates[langUrl].fieldTitle_direction[lang]"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1: 'directionType', value: $event })" />
                                <!-- <BaseTextField
                                    :value="trip.directionType"
                                    label="Направление"
                                    :error-messages="errors[0]"
                                    :counter="60"
                                    @input="setTripValue({ prop_1: 'directionType', value: $event })" /> -->
                            </div> 
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:300">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_tripDescription[lang] }}</div>
                                <BaseTextArea
                                    :value="trip.description"
                                    :label="translates[langUrl].fieldTitle_tripDescription[lang]"
                                    :error-messages="errors[0]"
                                    :counter="250"
                                    @input="setTripValue({ prop_1: 'description', value: $event })" />
                            </div> 
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:100">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_tripCode[lang] }}</div>
                                <BaseTextField
                                    :class="{ 'error--text': serverErrors.code }"
                                    :details="serverErrors.code"
                                    :value="trip.code"
                                    :label="translates[langUrl].fieldTitle_tripCode[lang]"
                                    :error-messages="errors[0] || serverErrors.code"
                                    :counter="100"
                                    @input="setTripValue({ prop_1: 'code', value: $event })" />
                            </div> 
                        </ValidationProvider>
                        <!-- <div class="view-trip-configurator__input-container">
                            <div class="view-trip-configurator__input-container__input-name">Контактный номер</div>
                            <BaseTextField
                                :value="trip.phone"
                                label="Контактный номер"
                                type="tel"
                                autocomplete="off"
                                :counter="100"
                                @input="setTripValue({ prop_1: 'phone', value: $event })" />
                        </div>
                        <div class="view-trip-configurator__input-container">
                            <div class="view-trip-configurator__input-container__input-name">Контактная почта</div>
                            <BaseTextField
                                :value="trip.mail"
                                label="Контактная почта"
                                placeholder="zapros@shinytravel.ru"
                                :counter="100"
                                @input="setTripValue({ prop_1: 'mail', value: $event })" />
                        </div> -->
                        <div class="view-trip-configurator__input-container" v-if="landing">
                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_tripDomain[lang] }}</div>
                            <BaseTextField
                                :value="trip.host"
                                :label="translates[langUrl].fieldTitle_tripDomain[lang]"
                                :counter="100"
                                @input="setTripValue({ prop_1: 'host', value: $event })" />
                            <div class="view-trip-configurator__input-container__info" @click="showInstruction">
                                <img src="../../assets/Question.svg" class="view-trip-configurator__input-container__info__icon" />
                            </div>
                        </div>
                        <div class="view-trip-configurator__input-container" v-if="landing">
                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_counterNumber[lang] }}</div>
                            <BaseTextField
                                :value="trip.yandexMetricaId"
                                :label="translates[langUrl].fieldTitle_counterNumber[lang]"
                                :counter="100"
                                @input="setTripValue({ prop_1: 'yandexMetricaId', value: $event })" />
                        </div>
                        <div class="view-trip-configurator__input-container" v-if="landing">
                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_counterScripts[lang] }}</div>
                            <div class="view-trip-configurator__input-container__input-content__list">
                                <template v-for="(script, scripts_i) in trip.scripts"> 
                                    <div :key="scripts_i"> 
                                        <BaseTextArea
                                            :value="script"
                                            :label="translates[langUrl].fieldTitle_counterScripts[lang]"
                                            @input="setTripValue({ prop_1: 'scripts', prop_2: scripts_i, value: $event })" />
                                        <DeleteButton @remove="removeScript(scripts_i)">{{ translates[langUrl].button_deleteScript[lang] }}</DeleteButton>
                                    </div>
                                </template>
                                <AddButton @add="addScript">{{ translates[langUrl].button_addScript[lang] }}</AddButton>
                            </div>
                        </div>

                        <template v-if="landing">
                            <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].fieldTitle_logo[lang] }}</h3>
                            <div class="view-trip-configurator__input-container">
                                <div v-if="isDesktop" class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_logo[lang] }}</div>
                                <div v-if="trip.logo && trip.logo !== ''" class="img-container">
                                    <div class="img_slot__picture" :style="`background-image: url(${ imageSrc(trip.logo) })`">
                                        <div
                                            class="img_slot__main-picture img_slot__main-picture__button"
                                            @click="setTripValue({ prop_1: 'logo', value: '' })"
                                        >{{ translates[langUrl].button_delete[lang] }}</div>
                                    </div>
                                </div>
                                <ImgLoader
                                    v-else
                                    :text="translates[langUrl].button_addPicture[lang]"
                                    @change="setTripValue({ prop_1: 'logo', value: $event })"
                                />
                            </div>
                        </template>
                        <div class="view-trip-configurator__input-container" v-if="landing">
                            <div class="view-trip-configurator__input-container__input-name">Instagram</div>
                            <BaseTextField
                                :value="trip.Inst"
                                :label="translates[langUrl].fieldTitle_tripDomain[lang]"
                                @input="setTripValue({ prop_1: 'Inst', value: $event })" />
                        </div>
                        <div class="view-trip-configurator__input-container" v-if="landing">
                            <div class="view-trip-configurator__input-container__input-name">VK</div>
                            <BaseTextField
                                :value="trip.VK"
                                placeholder="egorteezy"
                                :label="translates[langUrl].fieldTitle_tripDomain[lang]"
                                @input="setTripValue({ prop_1: 'VK', value: $event })" />
                        </div>
                        <div class="view-trip-configurator__input-container" v-if="landing">
                            <div class="view-trip-configurator__input-container__input-name">Telegram</div>
                            <BaseTextField
                                :value="trip.telegram"
                                placeholder="egorteezy"
                                :label="translates[langUrl].fieldTitle_tripDomain[lang]"
                                @input="setTripValue({ prop_1: 'telegram', value: $event })" />
                        </div>
                        <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].fieldTitle_images[lang] }}</h3>
                        <div class="view-trip-configurator__input-container">
                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_coverImage[lang] }}</div>
                            <div class="img-container">
                                <template v-for="(picture, index) in trip.pictures">  
                                    <div :key="index" class="img_slot">
                                        <div :style="`background-image: url(${ imageSrc(picture) })` " class="img_slot__picture">
                                            <div v-if="trip.mainPictureIndex === index" class="img_slot__main-picture">{{ translates[langUrl].label_mainPicture[lang] }}</div>
                                            <div class="img_slot__picture-menu">
                                                <v-menu bottom offset-y left offset-x>
                                                    <template #activator="{ on, attrs }">
                                                        <img 
                                                            src="../../assets/Group-menu.svg"
                                                            v-bind="attrs" 
                                                            v-on="on" />
                                                    </template>
                                                    <v-list>
                                                        <v-list-item @click="setTripValue({ prop_1: 'mainPictureIndex', value: index })">
                                                            <v-list-item-title>{{ translates[langUrl].button_mainPicture[lang] }}</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item @click="removePicture({ prop_1: 'pictures', value: index })">
                                                            <v-list-item-title>{{ translates[langUrl].button_delete[lang] }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <ImgLoader
                                    :text="translates[langUrl].button_addPicture[lang]"
                                    @change="setTripPicture({ prop_1: 'pictures', value: $event })" 
                                    quality="high"
                                />
                            </div>
                        </div>
                        <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="routBack">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <div class="view-trip-configurator__desktop-buttons__rigth-container">
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(1)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                        </div>
                        <div v-else class="buttons">
                            <div class="view-trip-configurator__step-buttons">
                                <BaseButton
                                    disabled
                                    class="view-trip-configurator__step-buttons__button"
                                    @click="routBack">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(1)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                        </div>
                    </ValidationObserver>
                    <v-dialog
                        v-model="hostIstruction"
                        max-width="1500px"
                    >
                        <div class="view-trip-configurator__helper">
                            <div class="view-trip-configurator__helper__title">Привязка домена</div>
                            <din class="view-trip-configurator__helper__text">1. Для вашего домена пропишите А-запись с указанием нашего IP: 31.192.104.87</din>
                            <img src="../../assets/2.jpg" class="view-trip-configurator__helper__picture"/>
                            <div class="view-trip-configurator__helper__close" @click="showInstruction"></div>
                            <div class="view-trip-configurator__helper__text">
                                2. После регистрации вашего домена можно добавить его в данное поле.
                                Домен должен быть написан в формате "adventureacademy.ru", без префиксов.
                                <br>
                                3. После завершения заполнения тура, нужно отправить его на модерацию.
                                <br>
                                4. После подтверждения вашего тура администратором, домен будет автоматически создан и на нем разместиться ваш тур.
                            </div>
                        </div>
                        
                    </v-dialog>
                </v-tab-item>
                <v-tab-item value="step_2">
                    <ValidationObserver ref="validator_2" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_organizer[lang] }}</h1>
                        </div>

                        <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].fieldTitle_organizer_avatar[lang] }}</h3>
                        <div class="view-trip-configurator__input-container">
                            <div v-if="isDesktop" class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_organizer_avatar[lang] }}</div>
                            <div v-if="trip.organizer.avatar && trip.organizer.avatar !== ''" class="img-container">
                                <div class="img_slot__picture" :style="`background-image: url(${ imageSrc(trip.organizer.avatar) })`">
                                    <div
                                        class="img_slot__main-picture img_slot__main-picture__button"
                                        @click="setTripValue({ prop_1: 'organizer', prop_2: 'avatar', value: '' })"
                                    >{{ translates[langUrl].button_delete[lang] }}</div>
                                </div>
                            </div>
                            <ImgLoader
                                v-else
                                :text="translates[langUrl].button_addPicture[lang]"
                                @change="setTripValue({ prop_1: 'organizer', prop_2: 'avatar', value: $event })"
                            />
                        </div>

                        <div class="view-trip-configurator__input-container">
                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_organizer_firstname[lang] }}</div>
                            <BaseTextField
                                :value="trip.organizer.firstname"
                                :label="translates[langUrl].fieldTitle_organizer_firstname[lang]"
                                @input="setTripValue({ prop_1: 'organizer', prop_2: 'firstname', value: $event })" />
                        </div>
                        <div class="view-trip-configurator__input-container">
                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_organizer_lastname[lang] }}</div>
                            <BaseTextField
                                :value="trip.organizer.lastname"
                                :label="translates[langUrl].fieldTitle_organizer_lastname[lang]"
                                @input="setTripValue({ prop_1: 'organizer', prop_2: 'lastname', value: $event })" />
                        </div>
                        <div class="view-trip-configurator__input-container">
                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_organizer_phone[lang] }}</div>
                            <BaseTextField
                                :value="trip.organizer.phone"
                                :label="translates[langUrl].fieldTitle_organizer_phone[lang]"
                                @input="setTripValue({ prop_1: 'organizer', prop_2: 'phone', value: $event })" />
                        </div>
                        <div class="view-trip-configurator__input-container">
                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_organizer_email[lang] }}</div>
                            <BaseTextField
                                :value="trip.organizer.email"
                                :label="translates[langUrl].fieldTitle_organizer_email[lang]"
                                @input="setTripValue({ prop_1: 'organizer', prop_2: 'email', value: $event })" />
                        </div>
                        <div class="view-trip-configurator__input-container">
                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_organizer_social[lang] }}</div>
                            <BaseTextField
                                :value="trip.organizer.social"
                                :label="translates[langUrl].fieldTitle_organizer_social[lang]"
                                @input="setTripValue({ prop_1: 'organizer', prop_2: 'social', value: $event })" />
                        </div>
                        <div class="view-trip-configurator__input-container">
                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_organizer_about[lang] }}</div>
                            <BaseTextArea
                                :value="trip.organizer.about"
                                :label="translates[langUrl].fieldTitle_organizer_about[lang]"
                                :counter="300"
                                @input="setTripValue({ prop_1: 'organizer', prop_2: 'about', value: $event })" />
                        </div> 
                        <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(2)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <div class="view-trip-configurator__desktop-buttons__rigth-container">
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(2)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                        </div>
                        <div v-else class="buttons">
                            <div class="view-trip-configurator__step-buttons">
                                <BaseButton
                                    class="view-trip-configurator__step-buttons__button"
                                    @click="back(2)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(2)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item value="step_3"> 
                    <ValidationObserver ref="validator_3" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_tripinNumber[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.numbers"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('numbers')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.numbers">
                            <ValidationProvider v-slot="{ errors }" rules="required|max:300">
                                <div class="view-trip-configurator__input-container" v-if="landing">
                                    <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_tripinNumber_description[lang] }}</div>
                                    <BaseTextArea
                                        :value="trip.numbersDescription"
                                        :label="translates[langUrl].fieldTitle_tripinNumber_description[lang]"
                                        :error-messages="errors[0]"
                                        :counter="300"
                                        @input="setTripValue({ prop_1: 'numbersDescription', value: $event })" />
                                </div> 
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="view-trip-configurator__input-container" v-if="landing">
                                    <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_tripDuration[lang] }}</div>
                                    <BaseTextField
                                        :value="trip.days"
                                        :label="translates[langUrl].fieldTitle_tripDuration[lang]"
                                        type="Number"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'days', value: $event })" />
                                </div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="view-trip-configurator__input-container" v-if="landing">
                                    <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_numberOfParticipants[lang] }}</div>
                                    <BaseTextField
                                        :value="trip.groupSize"
                                        :label="translates[langUrl].fieldTitle_numberOfParticipants[lang]"
                                        type="Number"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'groupSize', value: $event })" />
                                </div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="view-trip-configurator__input-container" v-if="landing">
                                    <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_cityCount[lang] }}</div>
                                    <BaseTextField
                                        :value="trip.sities"
                                        :label="translates[langUrl].fieldTitle_cityCount[lang]"
                                        type="Number"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'sities', value: $event })" />
                                </div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="view-trip-configurator__input-container" v-if="landing">
                                    <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_placesCount[lang] }}</div>
                                    <BaseTextField
                                        :value="trip.places"
                                        :label="translates[langUrl].fieldTitle_placesCount[lang]"
                                        type="Number"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'places', value: $event })" />
                                </div>
                            </ValidationProvider>
                            <!-- <div class="view-trip-configurator__warning">
                                Данная страница необходима для заполнения только при создании сайта на собственном домене. 
                                Если желаете создать таковой, необходимо возпользоваться сайтом 
                                <a href="https://adventureacademy.ru/" target="_blank">adventureacademy.ru</a>
                            </div> -->
                        </template>
                        <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(3)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <div class="view-trip-configurator__desktop-buttons__rigth-container">
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(3)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                        </div>
                        <div v-else class="buttons">
                            <div class="view-trip-configurator__step-buttons">
                                <BaseButton
                                    class="view-trip-configurator__step-buttons__button"
                                    @click="back(3)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(3)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item value="step_4">
                    <div class="view-trip-configurator__page-name__wrapper">
                        <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_map[lang] }}</h1>
                        <v-switch
                            v-if="landing"
                            :input-value="trip.visibleBlocks.map"
                            :label="translates[langUrl].button_showPage[lang]"
                            @change="switchVisibleBlock('map')"
                        />
                    </div>
                    <ValidationObserver ref="validator_4" slim>
                        <template v-if="trip.visibleBlocks.map">
                            <Map
                                :trip="trip" 
                                :text="{ 
                                    find: `${translates[langUrl].map_fieldTitle_find[lang]}`,
                                    findButton: `${translates[langUrl].map_button_find[lang]}`,
                                    addPoints: `${translates[langUrl].map_fieldTitle_addPoints[lang]}`,
                                    points: `${translates[langUrl].map_fieldTitle_points[lang]}`,
                                    map: `${translates[langUrl].map_fieldTitle_map[lang]}`,
                                }" 
                            />
                            <!-- <div class="view-trip-configurator__input-container" style="margin-bottom: 20px;">
                                <div class="view-trip-configurator__input-container__input-name">Как заполнить точки на карте?</div>
                                <iframe class="view-trip-configurator__input-container__tour-type" width="560" height="315" src="https://www.youtube.com/embed/tvEqZlFEbds" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div> -->
                        </template>
                        <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(4)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <div class="view-trip-configurator__desktop-buttons__rigth-container">
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(4)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                        </div>
                        <div v-else class="buttons">
                            <div class="view-trip-configurator__step-buttons">
                                <BaseButton
                                    class="view-trip-configurator__step-buttons__button"
                                    @click="back(4)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(4)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item value="step_5">
                    <ValidationObserver ref="validator_5" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_tripCharacteristics[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.info"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('info')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.info">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="view-trip-configurator__input-container">
                                    <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_loadLevel[lang] }}</div>
                                    <BaseSelect
                                        :value="trip.level"
                                        :items="levels"
                                        item-text="title"
                                        item-value="code"
                                        :label="translates[langUrl].fieldTitle_loadLevel[lang]"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'level', value: $event })" />
                                </div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="view-trip-configurator__input-container">
                                    <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_arrivalPrice[lang] }}</div>
                                    <BaseTextField
                                        :value="trip.price"
                                        :label="translates[langUrl].fieldTitle_arrivalPrice[lang]"
                                        :error-messages="errors[0]"
                                        @input="setTripPrice({ prop_1: 'price', value: $event })" />
                                </div> 
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="view-trip-configurator__input-container">
                                    <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_arrivalPlaces[lang] }}</div>
                                    <BaseTextField
                                        :value="trip.groupSize"
                                        :label="translates[langUrl].fieldTitle_arrivalPlaces[lang]"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'groupSize', value: $event })" />
                                </div> 
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="view-trip-configurator__input-container">
                                    <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_tripMainType[lang] }}</div>
                                    <BaseSelect
                                        :value="trip.mainType"
                                        :items="types"
                                        item-text="title"
                                        item-value="code"
                                        :label="translates[langUrl].fieldTitle_tripMainType[lang]"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'mainType', value: $event })" />
                                </div>
                            </ValidationProvider>
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_tripAdditionalType[lang] }}</div>
                                <div class="view-trip-configurator__input-container__input-content__tour-type">
                                    <template v-for="(additionalType, i) in trip.additionalTypes">
                                        <div :key="'additionalType' + i" class="view-trip-configurator__input-container__line">
                                            <BaseSelect
                                                :value="additionalType"
                                                :label="translates[langUrl].fieldTitle_tripAdditionalType[lang]"
                                                :items="types"
                                                item-text="title"
                                                item-value="code"
                                                @input="setTripValue({ prop_1: 'additionalTypes', prop_2: i, value: $event })" />
                                            <div v-if="trip.additionalTypes.length > 1" class="view-trip-configurator__tour-type__delete-button">
                                                <img
                                                    src="../../assets/Delete2.svg" 
                                                    class="view-trip-configurator__tour-type__delete-button__delete-icon" 
                                                    @click="removeTripValue({ prop_1: 'additionalTypes', value: i })"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="view-trip-configurator__addTripType-button" @click="addTripType">
                                <img src="../../assets/PlusPink.svg" class="view-trip-configurator__addTripType-button__icon" />
                                <div class="view-trip-configurator__addTripType-button__text">{{ translates[langUrl].button_addTripType[lang] }}</div>
                            </div>
                            <!-- <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">Теги</div>
                                <Hashtags
                                    placeholder="Теги"
                                    :value="trip.tags"
                                    @change="setTripValue({ prop_1: 'tags', value: $event })" />
                            </div> -->
                            <div class="view-trip-configurator__input-container" v-if="marketplace">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_label[lang] }}</div>
                                <BaseSelect
                                    :value="trip.label"
                                    :items="labels"
                                    item-text="title"
                                    item-value="code"
                                    :label="translates[langUrl].fieldTitle_label[lang]"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1: 'label', value: $event })" />
                            </div>
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_tourOperator[lang] }}</div>
                                <BaseTextField
                                    :value="trip.tourOperator"
                                    :label="translates[langUrl].fieldTitle_tourOperator[lang]"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1:'tourOperator', value: $event })" />
                            </div>
                            <!-- <ValidationProvider v-slot="{ errors }" rules="required|max:1020">
                                <div class="view-trip-configurator__input-container">
                                    <div class="view-trip-configurator__input-container__input-name">Размер группы (от)</div>
                                    <BaseTextField
                                        :value="trip.groupSizeFrom"
                                        label="Размер группы (от)"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'groupSizeFrom', value: $event })" />
                                </div>
                            </ValidationProvider> -->
                            <!-- <ValidationProvider v-slot="{ errors }" rules="required|max:1020">
                                <div class="view-trip-configurator__input-container">
                                    <div class="view-trip-configurator__input-container__input-name">Размер группы (до)</div>
                                    <BaseTextField
                                        :value="trip.groupSizeTo"
                                        label="Размер группы (до)"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'groupSizeTo', value: $event })" />
                                </div>
                            </ValidationProvider> -->
                            <!-- <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="view-trip-configurator__input-container">
                                    <div class="view-trip-configurator__input-container__input-name">Возраст (от)</div>
                                    <BaseTextField
                                        :value="trip.groupAgeFrom"
                                        label="Возраст (от)"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'groupAgeFrom', value: $event })" />
                                </div>
                            </ValidationProvider> -->
                            <!-- <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="view-trip-configurator__input-container">
                                    <div class="view-trip-configurator__input-container__input-name">Возраст (до)</div>
                                    <BaseTextField
                                        :value="trip.groupAgeTo"
                                        label="Возраст (до)"
                                        :error-messages="errors[0]"
                                        @input="setTripValue({ prop_1: 'groupAgeTo', value: $event })" />
                                </div>
                            </ValidationProvider> -->
                            <!-- <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">Язык</div>
                                <BaseTextField
                                    :value="trip.language"
                                    label="Язык"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1: 'language', value: $event })" />
                            </div> -->
                            <!-- <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">Гигиенические меры</div>
                                <BaseTextField
                                    :value="trip.hygiene"
                                    label="Гигиенические меры"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1: 'hygiene', value: $event })" />
                            </div> -->
                        </template>
                        <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(5)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <div class="view-trip-configurator__desktop-buttons__rigth-container">
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(5)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                        </div>
                        <div v-else class="buttons">
                            <div class="view-trip-configurator__step-buttons">
                                <BaseButton
                                    class="view-trip-configurator__step-buttons__button"
                                    @click="back(5)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(5)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item value="step_6">
                    <ValidationObserver ref="validator_6" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_tripsPhotos[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.photos"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('photos')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.photos">
                            <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].fieldTitle_travelersPhotos[lang] }}</h3>
                            <div class="view-trip-configurator__input-container">
                                <div v-if="isDesktop" class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_travelersPhotos[lang] }}</div>
                                <div class="img-container">
                                    <template v-for="(photo, index) in trip.photos">
                                        <div :key="index" class="img_slot">
                                            <div class="img_slot__picture" :style="`background-image: url(${ imageSrc(photo.picture) })`">
                                                <div 
                                                    class="img_slot__main-picture img_slot__main-picture__button" 
                                                    @click="removePhoto(index)"
                                                >{{ translates[langUrl].button_delete[lang] }}</div>
                                            </div>
                                        </div>
                                    </template>
                                    <ImgLoader
                                        :text="translates[langUrl].button_addPicture[lang]"
                                        @change="setTripPicture({ prop_1: 'photos', value: { picture: $event } })" 
                                    />
                                </div>
                            </div>
                        </template>
                        <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(6)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <div class="view-trip-configurator__desktop-buttons__rigth-container">
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(6)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                        </div>
                        <div v-else class="buttons">
                            <div class="view-trip-configurator__step-buttons">
                                <BaseButton
                                    class="view-trip-configurator__step-buttons__button"
                                    @click="back(6)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(6)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item value="step_7">
                    <ValidationObserver ref="validator_7" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_whatWillHappen[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.moments"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('moments')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.moments">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_whatWillHappen_description[lang] }}</div>
                                <BaseTextArea
                                    :value="trip.momentsDescription"
                                    :label="translates[langUrl].fieldTitle_description[lang]"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1: 'momentsDescription', value: $event })" />
                            </div>
                            <template v-for="(moment, i) in trip.moments">
                                <div :key="i">
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_event[lang] }}</div>
                                        <BaseTextField
                                            :value="moment.title"
                                            :label="translates[langUrl].fieldTitle_event[lang]"
                                            :error-messages="errors[0]"
                                            :counter="30"
                                            @input="setTripValue({ prop_1: 'moments',prop_2: i, prop_3: 'title', value: $event })" />
                                    </div>
                                    <ValidationProvider v-slot="{ errors }" rules="required|max:1020">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_description[lang] }}</div>
                                            <BaseTextArea
                                                :value="moment.description"
                                                :label="translates[langUrl].fieldTitle_description[lang]"
                                                :error-messages="errors[0]"
                                                :counter="300"
                                                @input="setTripValue({ prop_1: 'moments', prop_2: i, prop_3: 'description', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <DeleteButton
                                        v-if="trip.moments && trip.moments.length > 1"
                                        @remove="removeMoment(i)">{{ translates[langUrl].button_deleteEvent[lang] }}
                                    </DeleteButton>
                                    <div v-if="trip.moments.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton v-if="trip.moments && trip.moments.length < 6" @add="addMoment">{{ translates[langUrl].button_addEvent[lang] }}</AddButton>
                        </template>
                        <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(7)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <div class="view-trip-configurator__desktop-buttons__rigth-container">
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(7)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                        </div>
                        <div v-else class="buttons">
                            <div class="view-trip-configurator__step-buttons">
                                <BaseButton
                                    class="view-trip-configurator__step-buttons__button"
                                    @click="back(7)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(7)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item value="step_8">
                    <ValidationObserver ref="validator_8" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_mainPlaces[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.mainPlaces"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('mainPlaces')"
                            />
                        </div>
                        <template v-for="(mainPlace, index) in trip.mainPlaces">
                            <div :key="index">
                                <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].button_addPicture[lang] }}</h3>
                                <div class="view-trip-configurator__input-container">
                                    <div v-if="isDesktop" class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].button_addPicture[lang] }}</div>
                                    <div v-if="mainPlace.picture && mainPlace.picture !== ''" class="img-container">
                                        <div class="img_slot__picture" :style="`background-image: url(${ imageSrc(mainPlace.picture) })`">
                                            <div
                                                class="img_slot__main-picture img_slot__main-picture__button" 
                                                @click="setTripValue({ prop_1: 'mainPlaces', prop_2: index, prop_3: 'picture', value: '' })"
                                            >{{ translates[langUrl].button_delete[lang] }}</div>
                                        </div>
                                    </div>
                                    <ImgLoader 
                                        v-else
                                        :text="translates[langUrl].button_addPicture[lang]"
                                        @change="setTripValue({ prop_1: 'mainPlaces', prop_2: index, prop_3: 'picture', value: $event })"
                                    />
                                </div>
                                <ValidationProvider v-slot="{ errors }" rules="required|max:1020">
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name"> {{ translates[langUrl].fieldTitle_description[lang] }}</div>
                                        <BaseTextField
                                            :value="mainPlace.description"
                                            :label="translates[langUrl].fieldTitle_description[lang]"
                                            :error-messages="errors[0]"
                                            :counter="60"
                                            @input="setTripValue({ prop_1: 'mainPlaces', prop_2: index, prop_3: 'description', value: $event })" />
                                    </div>
                                </ValidationProvider>
                                <DeleteButton
                                    v-if="trip.mainPlaces.length > 1"
                                    @remove="removeMainPlace(index)">{{ translates[langUrl].button_deletePlace[lang] }}
                                </DeleteButton>
                                <div v-if="trip.mainPlaces.length > 1" class="view-trip-configurator__form-card__outline"></div>
                            </div>
                        </template>
                        <AddButton @add="addMainPlace">{{ translates[langUrl].button_addPlace[lang] }}</AddButton>
                        <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(8)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <div class="view-trip-configurator__desktop-buttons__rigth-container">
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(8)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                        </div>
                        <div v-else class="buttons">
                            <div class="view-trip-configurator__step-buttons">
                                <BaseButton
                                    class="view-trip-configurator__step-buttons__button"
                                    @click="back(8)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(8)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item value="step_9">
                    <ValidationObserver ref="validator_9" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_benefits[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.benefits"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('benefits')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.benefits">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_benefitsDescription[lang] }}</div>
                                <BaseTextArea
                                    :value="trip.benefitsDescription"
                                    :label="translates[langUrl].fieldTitle_benefitsDescription[lang]"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1: 'benefitsDescription', value: $event })" />
                            </div>

                            <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].button_addPicture[lang] }}</h3>
                            <div class="view-trip-configurator__input-container">
                                <div v-if="isDesktop" class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].button_addPicture[lang] }}</div>
                                <div class="img-container">
                                    <div
                                        v-if="trip.benefitsPicture && trip.benefitsPicture !== ''"
                                        :style="`background-image: url(${ imageSrc(trip.benefitsPicture) })`"
                                        class="img_slot__picture"
                                    >
                                        <div
                                            class="img_slot__main-picture img_slot__main-picture__button" 
                                            @click="setTripValue({ prop_1: 'benefitsPicture', value: '' })"
                                        >{{ translates[langUrl].button_delete[lang] }}</div>
                                    </div>
                                    <ImgLoader
                                        v-else
                                        :text="translates[langUrl].button_addPicture[lang]"
                                        @change="setTripValue({ prop_1: 'benefitsPicture', value: $event })"
                                    />
                                </div>
                            </div>
                            <template v-for="(benefit, i) in trip.benefits">
                                <div :key="i">
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_benefit[lang] }}</div>
                                        <BaseTextField
                                            :value="benefit.title"
                                            :label="translates[langUrl].fieldTitle_benefit[lang]"
                                            :error-messages="errors[0]"
                                            :counter="30"
                                            @input="setTripValue({ prop_1: 'benefits',prop_2: i, prop_3: 'title', value: $event })" />
                                    </div>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_benefitType[lang] }}</div>
                                        <div class="view-trip-configurator__input-container__line">
                                            <BaseSelect
                                                :value="benefit.type"
                                                :label="translates[langUrl].fieldTitle_benefitType[lang]"
                                                :items="benefitsTypes"
                                                item-text="title"
                                                item-value="code"
                                                @input="setTripValue({ prop_1: 'benefits', prop_2: i, prop_3: 'type', value: $event })" />
                                        </div>
                                    </div>
                                    <ValidationProvider v-slot="{ errors }" rules="required|max:1020">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_description[lang] }}</div>
                                            <BaseTextArea
                                                :value="benefit.description"
                                                :label="translates[langUrl].fieldTitle_description[lang]"
                                                :error-messages="errors[0]"
                                                :counter="300"
                                                @input="setTripValue({ prop_1: 'benefits', prop_2: i, prop_3: 'description', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <DeleteButton
                                        v-if="trip.benefits.length > 1"
                                        @remove="removeBenefit(i)">{{ translates[langUrl].button_deleteBenefit[lang] }}
                                    </DeleteButton>
                                    <div v-if="trip.benefits.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton v-if="trip.benefits.length < 6" @add="addBenefit">{{ translates[langUrl].button_addBenefit[lang] }}</AddButton>
                        </template>
                        <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(9)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <div class="view-trip-configurator__desktop-buttons__rigth-container">
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(9)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                        </div>
                        <div v-else class="buttons">
                            <div class="view-trip-configurator__step-buttons">
                                <BaseButton
                                    class="view-trip-configurator__step-buttons__button"
                                    @click="back(9)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    @click="next(9)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item value="step_10">
                    <ValidationObserver ref="validator_10" rules="required" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_programs[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.programs"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('programs')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.programs">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_presentation[lang] }}</div>
                                <div style="width: 100%; display: flex; gap: 10px;">
                                    <div v-if="trip.PDF && trip.PDF !== ''" class="file__pattern">
                                        {{ translates[langUrl].button_downloadPresentation[lang] }}
                                        <a
                                            v-if="user._id"
                                            class="file__pattern__link"
                                            :href="downloadProgram(trip.PDF)"
                                            target="_blank"
                                        ></a>
                                        <v-icon class="mr-1" color="#FF385C">
                                            mdi-download
                                        </v-icon></div>
                                    <BaseUploader
                                        :value="trip.PDF"
                                        :text="translates[langUrl].button_uploadPresentation[lang]"
                                        @change="setTripValue({ prop_1: 'PDF', value: $event })"
                                    />
                                </div>
                            </div>
                            <template v-for="(program, index) in trip.programs">
                                <div :key="index" class="view-trip-configurator__form-card">
                                    <ValidationProvider v-slot="{ errors }" rules="required|max:50">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_day[lang] }} {{ index + 1 }}</div>
                                            <BaseTextField
                                                :value="program.title"
                                                :error-messages="errors[0]"
                                                :counter="50"
                                                :label="translates[langUrl].fieldTitle_day[lang] + ' ' + index + 1"
                                                @input="setTripValue({ prop_1: 'programs', prop_2: index, prop_3: 'title', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_description[lang] }}</div>
                                            <BaseTextArea
                                                :value="program.description"
                                                :label="translates[langUrl].fieldTitle_description[lang]"
                                                :error-messages="errors[0]"
                                                :counter="1800"
                                                @input="setTripValue({ prop_1: 'programs', prop_2: index, prop_3: 'description', value: $event })" />
                                        </div>
                                    </ValidationProvider>

                                    <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].fieldTitle_images[lang] }}</h3>
                                    <div class="view-trip-configurator__input-container">
                                        <div v-if="isDesktop" class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_images[lang] }}</div>
                                        <div class="img-container">
                                            <template v-for="(picture, i) in program.pictures">
                                                <div
                                                    :key="'picture' + index + '-' + i"
                                                    class="img_slot"
                                                >
                                                    <div :style="`background-image: url(${ imageSrc(picture) })` " class="img_slot__picture">
                                                        <div 
                                                            class="img_slot__main-picture img_slot__main-picture__button" 
                                                            @click="removePicture({ prop_1: 'programs', prop_2: index, prop_3: 'pictures', value: i })"
                                                        >{{ translates[langUrl].button_delete[lang] }}</div>
                                                    </div>
                                                
                                                </div>
                                            </template>
                                            <ImgLoader
                                                :text="translates[langUrl].button_addPicture[lang]"
                                                @change="setTripPicture({ prop_1: 'programs', prop_2: index, prop_3: 'pictures', prop_4: i, value: $event })"
                                            />
                                        </div>
                                    </div>
                                    <DeleteButton
                                        v-if="trip.programs.length > 1"
                                        @remove="removeProgram(index)">{{ translates[langUrl].button_deleteDay[lang] }}</DeleteButton>
                                    <div v-if="trip.programs.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton @add="addProgram">{{ translates[langUrl].button_nextDay[lang] }}</AddButton>
                        </template>
                    </ValidationObserver>
                    <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                        <BaseButton
                            class="view-trip-configurator__step-buttons__button"
                            @click="back(10)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                        <div class="view-trip-configurator__desktop-buttons__rigth-container">
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(10)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                    </div>
                    <div v-else class="buttons">
                        <div class="view-trip-configurator__step-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(10)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(10)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                        <BaseButton 
                            class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                            @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                    </div>
                </v-tab-item>
                <v-tab-item value="step_11">
                    <ValidationObserver ref="validator_11" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_included[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.included"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('included')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.included">
                            <template v-for="(included, index) in trip.included">
                                <div :key="'included' + index" class="view-trip-configurator__form-card">
                                    <ValidationProvider v-slot="{ errors }" rules="required|max:50">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_title[lang] }}</div>
                                            <BaseTextField
                                                :value="included.title"
                                                :error-messages="errors[0]"
                                                :counter="50"
                                                :label="translates[langUrl].fieldTitle_title[lang]"
                                                @input="setTripValue({ prop_1: 'included', prop_2: index, prop_3: 'title', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_description[lang] }}</div>
                                            <BaseTextArea
                                                :value="included.description"
                                                :label="translates[langUrl].fieldTitle_description[lang]"
                                                :error-messages="errors[0]"
                                                :counter="1020"
                                                @input="setTripValue({ prop_1: 'included', prop_2: index, prop_3: 'description', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <DeleteButton
                                        v-if="trip.included.length > 1"
                                        @remove="removeIncluded(index)">{{ translates[langUrl].button_deleteBlock[lang] }}</DeleteButton>
                                    <div v-if="trip.included.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton @add="addIncluded">{{ translates[langUrl].button_addBlock[lang] }}</AddButton>

                            <div class="view-trip-configurator__page-name__wrapper">
                                <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_notIncluded[lang] }}</h1>
                            </div>
                            <template v-for="(notIncluded, index) in trip.notIncluded">
                                <div :key="'notIncluded' + index" class="view-trip-configurator__form-card">
                                    <ValidationProvider v-slot="{ errors }" rules="required|max:50">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_title[lang] }}</div>
                                            <BaseTextField
                                                :value="notIncluded.title"
                                                :error-messages="errors[0]"
                                                :counter="50"
                                                :label="translates[langUrl].fieldTitle_title[lang]"
                                                @input="setTripValue({ prop_1: 'notIncluded', prop_2: index, prop_3: 'title', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_description[lang] }}</div>
                                            <BaseTextArea
                                                :value="notIncluded.description"
                                                :label="translates[langUrl].fieldTitle_description[lang]"
                                                :error-messages="errors[0]"
                                                :counter="1020"
                                                @input="setTripValue({ prop_1: 'notIncluded', prop_2: index, prop_3: 'description', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <DeleteButton
                                        v-if="trip.notIncluded.length > 1"
                                        @remove="removeNotIncluded(index)">{{ translates[langUrl].button_deleteBlock[lang] }}</DeleteButton>
                                    <div v-if="trip.notIncluded.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton @add="addNotIncluded">{{ translates[langUrl].button_addBlock[lang] }}</AddButton>
                        </template>
                    </ValidationObserver>
                    <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                        <BaseButton
                            class="view-trip-configurator__step-buttons__button"
                            @click="back(11)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                        <div class="view-trip-configurator__desktop-buttons__rigth-container">
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(11)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                    </div>
                    <div v-else class="buttons">
                        <div class="view-trip-configurator__step-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(11)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(11)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                        <BaseButton 
                            class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                            @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                    </div>
                </v-tab-item>
                <v-tab-item value="step_12">
                    <ValidationObserver ref="validator_12" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_accommodations[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.accommodations"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('accommodations')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.accommodations">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_accommodationDescription[lang] }}</div>
                                <BaseTextArea
                                    :value="trip.accommodationsDescription"
                                    :label="translates[langUrl].fieldTitle_accommodationDescription[lang]"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1: 'accommodationsDescription', value: $event })" />
                            </div>
                            <template v-for="(accommodation, index) in trip.accommodations">
                                <div :key="index" class="view-trip-configurator__form-card">
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_accommodationPlace[lang] }}</div>
                                        <BaseTextField
                                            :value="accommodation.title"
                                            :label="translates[langUrl].fieldTitle_accommodationPlace[lang]"
                                            :error-messages="errors[0]"
                                            :counter="30"
                                            @input="setTripValue({ prop_1: 'accommodations', prop_2: index, prop_3: 'title', value: $event })" />
                                    </div>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_accommodationPlaceDescription[lang] }}</div>
                                        <BaseTextArea
                                            :value="accommodation.description"
                                            :label="translates[langUrl].fieldTitle_accommodationPlaceDescription[lang]"
                                            :error-messages="errors[0]"
                                            :counter="300"
                                            @input="setTripValue({ prop_1: 'accommodations', prop_2: index, prop_3: 'description', value: $event })" />
                                    </div>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_accommodationType[lang] }}</div>
                                        <div class="view-trip-configurator__input-container__line">
                                            <BaseSelect
                                                :value="accommodation.type"
                                                :label="translates[langUrl].fieldTitle_accommodationType[lang]"
                                                :items="accommodationsTypes"
                                                item-text="title"
                                                item-value="code"
                                                @input="setTripValue({ prop_1: 'accommodations', prop_2: index, prop_3: 'type', value: $event })" />
                                        </div>
                                    </div> 
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_accommodationTypeDescription[lang] }}</div>
                                        <BaseTextField
                                            :value="accommodation.bookingLink"
                                            :error-messages="errors[0]"
                                            :label="translates[langUrl].fieldTitle_accommodationTypeDescription[lang]"
                                            @input="setTripValue({ prop_1: 'accommodations', prop_2: index, prop_3: 'bookingLink', value: $event })" />
                                    </div>
                                    <!-- <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">Рэйтинг места (от 1  до 5)</div>
                                        <BaseTextField
                                            :value="accommodation.raiting"
                                            label="Рэйтинг места (от 1  до 10)"
                                            :error-messages="errors[0]"
                                            type="Number"
                                            max="10"
                                            @input="setTripValue({ prop_1: 'accommodations', prop_2: index, prop_3: 'raiting', value: $event })" />
                                    </div> -->

                                    <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].fieldTitle_accommodationPictures[lang] }}</h3>
                                    <div class="view-trip-configurator__input-container">
                                        <div v-if="isDesktop" class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_accommodationPictures[lang] }}</div>
                                        <div class="img-container">
                                            <template v-for="(picture, i) in accommodation.pictures">  
                                                <div :key="i" class="img_slot">
                                                    <div class="img_slot__picture" :style="`background-image: url(${ imageSrc(picture) })`">
                                                        <div v-if="accommodation.mainPictureIndex === i" class="img_slot__main-picture">{{ translates[langUrl].label_mainPicture[lang] }}</div>
                                                        <div class="img_slot__picture-menu">
                                                            <v-menu 
                                                                bottom 
                                                                offset-y 
                                                                left 
                                                                offset-x>
                                                                <template #activator="{ on, attrs }">
                                                                    <img
                                                                        src="../../assets/Group-menu.svg"
                                                                        v-bind="attrs" 
                                                                        v-on="on" />
                                                                </template>
                                                                <v-list>
                                                                    <v-list-item @click="setTripValue({ prop_1: 'accommodations', prop_2: index, prop_3: 'mainPictureIndex', value: i })">
                                                                        <v-list-item-title>{{ translates[langUrl].button_mainPicture[lang] }}</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item @click="removePicture({ prop_1: 'accommodations', prop_2: index, prop_3: 'pictures', value: i })">
                                                                        <v-list-item-title>{{ translates[langUrl].button_delete[lang] }}</v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <ImgLoader
                                                :text="translates[langUrl].button_addPicture[lang]"
                                                @change="setTripPicture({ prop_1: 'accommodations', prop_2: index, prop_3: 'pictures', value: $event })"
                                            />
                                        </div>
                                    </div>
                                    
                                    <DeleteButton
                                        v-if="trip.accommodations.length > 1"
                                        @remove="removeAccommodation(index)">{{ translates[langUrl].button_deleteAccommodation }}
                                    </DeleteButton>
                                    <div v-if="trip.accommodations.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton @add="addAccommodation">{{ translates[langUrl].button_addAccommodation[lang] }}</AddButton>
                        </template>
                    </ValidationObserver>
                    <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                        <BaseButton
                            class="view-trip-configurator__step-buttons__button"
                            @click="back(12)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                        <div class="view-trip-configurator__desktop-buttons__rigth-container">
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(12)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                    </div>
                    <div v-else class="buttons">
                        <div class="view-trip-configurator__step-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(12)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(12)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                        <BaseButton 
                            class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                            @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                    </div>
                </v-tab-item>
                <v-tab-item value="step_13">
                    <ValidationObserver ref="validator_13" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_transports[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.transports"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('transports')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.transports">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_transportDescription[lang] }}</div>
                                <BaseTextArea
                                    :value="trip.transportsDescription"
                                    :label="translates[langUrl].fieldTitle_transportDescription[lang]"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1: 'transportsDescription', value: $event })" />
                            </div>
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_transportTypes[lang] }}</div>
                                <div class="view-trip-configurator__input-container__input-content__tour-type">
                                    <template v-for="(transportsType, i) in trip.transportsTypes">
                                        <div :key="'transportsType' + i" class="view-trip-configurator__input-container__line">
                                            <BaseSelect
                                                :value="transportsType"
                                                :label="translates[langUrl].fieldTitle_transportTypes[lang]"
                                                :items="transportTypes"
                                                item-text="title"
                                                item-value="code"
                                                @input="setTripValue({ prop_1: 'transportsTypes', prop_2: i, value: $event })" />
                                            <div class="view-trip-configurator__tour-type__delete-button">
                                                <img
                                                    src="../../assets/Delete2.svg" 
                                                    class="view-trip-configurator__tour-type__delete-button__delete-icon" 
                                                    @click="removeTripValue({ prop_1: 'transportsTypes', value: i })" 
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="view-trip-configurator__addTripType-button" @click="addTransportsType">
                                <img src="../../assets/PlusPink.svg" class="view-trip-configurator__addTripType-button__icon" />
                                <div class="view-trip-configurator__addTripType-button__text">{{ translates[langUrl].button_addTransportType[lang] }}</div>
                            </div>
                            <template v-for="(transport, index) in trip.transports">
                                <div :key="index" class="view-trip-configurator__form-card">
                                    <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].button_addPicture[lang] }}</h3>
                                    <div class="view-trip-configurator__input-container">
                                        <div v-if="isDesktop" class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].button_addPicture[lang] }}</div>
                                        <div class="img-container">
                                            <div
                                                v-if="transport.picture && transport.picture !== ''"
                                                :style="`background-image: url(${ imageSrc(transport.picture) })`"
                                                class="img_slot__picture"
                                            >
                                                <div
                                                    class="img_slot__main-picture img_slot__main-picture__button" 
                                                    @click="setTripValue({ prop_1: 'transports', prop_2: index, prop_3: 'picture', value: '' })"
                                                >{{ translates[langUrl].button_delete[lang] }}</div>
                                            </div>
                                            <ImgLoader
                                                v-else
                                                :text="translates[langUrl].button_addPicture[lang]"
                                                @change="setTripValue({ prop_1: 'transports', prop_2: index, prop_3: 'picture', value: $event })"
                                            />
                                        </div>
                                    </div>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_transportPictireDescription[lang] }}</div>
                                        <BaseTextField
                                            :value="transport.description"
                                            :label="translates[langUrl].fieldTitle_transportPictireDescription[lang]"
                                            :error-messages="errors[0]"
                                            :counter="120"
                                            @input="setTripValue({ prop_1: 'transports', prop_2: index, prop_3: 'description', value: $event })" />
                                    </div>
                                    <DeleteButton
                                        v-if="trip.transports.length > 1"
                                        @remove="removeTransport(index)">{{ translates[langUrl].button_deleteTransport[lang] }}
                                    </DeleteButton>
                                    <div v-if="trip.transports.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton @add="addTransport">{{ translates[langUrl].button_addTransport[lang] }}</AddButton>
                        </template>
                    </ValidationObserver>
                    <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                        <BaseButton
                            class="view-trip-configurator__step-buttons__button"
                            @click="back(13)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                        <div class="view-trip-configurator__desktop-buttons__rigth-container">
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(13)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                    </div>
                    <div v-else class="buttons">
                        <div class="view-trip-configurator__step-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(13)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(13)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                        <BaseButton 
                            class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                            @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                    </div>
                </v-tab-item>
                <v-tab-item value="step_14">
                    <ValidationObserver ref="validator_14" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_activities[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.activities"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('activities')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.activities">
                            <div class="view-trip-configurator__input-container">
                                <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_activitiesDescription[lang] }}</div>
                                <BaseTextArea
                                    :value="trip.activitiesDescription"
                                    :label="translates[langUrl].fieldTitle_activitiesDescription[lang]"
                                    :error-messages="errors[0]"
                                    @input="setTripValue({ prop_1: 'activitiesDescription', value: $event })" />
                            </div>
                            <template v-for="(activity, index) in trip.activities">
                                <div :key="index" class="view-trip-configurator__form-card">
                                    <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].button_addPicture[lang] }}</h3>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].button_addPicture[lang] }}</div>
                                        <div class="img-container">
                                            <div
                                                v-if="activity.picture && activity.picture !== ''"
                                                :style="`background-image: url(${ imageSrc(activity.picture) })`"
                                                class="img_slot__picture"
                                            >
                                                <div
                                                    class="img_slot__main-picture img_slot__main-picture__button" 
                                                    @click="setTripValue({ prop_1: 'activities', prop_2: index, prop_3: 'picture', value: '' })"
                                                >{{ translates[langUrl].button_delete[lang] }}</div>
                                            </div>
                                            <ImgLoader
                                                v-else
                                                :text="translates[langUrl].button_addPicture[lang]"
                                                @change="setTripValue({ prop_1: 'activities', prop_2: index, prop_3: 'picture', value: $event })"
                                            />
                                        </div>
                                    </div>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_transportPictireDescription[lang] }}</div>
                                        <BaseTextField
                                            :value="activity.description"
                                            :label="translates[langUrl].fieldTitle_transportPictireDescription[lang]"
                                            :error-messages="errors[0]"
                                            :counter="120"
                                            @input="setTripValue({ prop_1: 'activities', prop_2: index, prop_3: 'description', value: $event })" />
                                    </div>
                                    <DeleteButton
                                        v-if="trip.activities.length > 1"
                                        @remove="removeActivitiy(index)">{{ translates[langUrl].button_deleteActivities[lang] }}
                                    </DeleteButton>
                                    <div v-if="trip.activities.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton @add="addActivitiy">{{ translates[langUrl].button_addActivities[lang] }}</AddButton>
                        </template>
                    </ValidationObserver>
                    <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                        <BaseButton
                            class="view-trip-configurator__step-buttons__button"
                            @click="back(14)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                        <div class="view-trip-configurator__desktop-buttons__rigth-container">
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(14)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                    </div>
                    <div v-else class="buttons">
                        <div class="view-trip-configurator__step-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(14)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(14)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                        <BaseButton 
                            class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                            @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                    </div>
                </v-tab-item>
                <v-tab-item value="step_15">
                    <ValidationObserver ref="validator_15" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_arrivals[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.arrivals"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('arrivals')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.arrivals">
                            <template v-for="(arrival, index) in trip.arrivals">
                                <div :key="'arrival' + index" class="view-trip-configurator__form-card">
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_arrivalStart[lang] }}</div>
                                            <div class="view-trip-configurator__input-container__input-content__tour-type">
                                                <BaseDatePicker
                                                    :value="arrival.start"
                                                    :max="arrival.finish"
                                                    :label="translates[langUrl].fieldTitle_arrivalStart[lang]"
                                                    :error-messages="errors[0]"
                                                    @change="setTripValue({ prop_1: 'arrivals', prop_2: index, prop_3: 'start', value: $event })" />
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_arrivalFinish[lang] }}</div>
                                            <div class="view-trip-configurator__input-container__input-content__tour-type">
                                                <BaseDatePicker
                                                    :value="arrival.finish"
                                                    :min="arrival.start"
                                                    :label="translates[langUrl].fieldTitle_arrivalFinish[lang]"
                                                    :error-messages="errors[0]"
                                                    @change="setTripValue({ prop_1: 'arrivals', prop_2: index, prop_3: 'finish', value: $event })" />
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_arrivalPlaces[lang] }}</div>
                                            <BaseTextField
                                                :value="arrival.places"
                                                :label="translates[langUrl].fieldTitle_arrivalPlaces[lang]"
                                                type="Number"
                                                min="1"
                                                :error-messages="errors[0]"
                                                @input="setTripValue({ prop_1: 'arrivals', prop_2: index, prop_3: 'places', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_selledPlaces[lang] }}</div>
                                            <BaseTextField
                                                :value="arrival.selled"
                                                :label="translates[langUrl].fieldTitle_selledPlaces[lang]"
                                                type="Number"
                                                min="1"
                                                :error-messages="errors[0]"
                                                @input="setTripValue({ prop_1: 'arrivals', prop_2: index, prop_3: 'selled', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <div class="view-trip-configurator__input-container" v-if="landing">
                                        <div class="view-trip-configurator__input-container__input-name">Валюта</div>
                                        <ValidationProvider v-slot="{ errors }" rules="required">
                                            <v-radio-group
                                                :value="arrival.currency"
                                                class="view-trip-configurator__radio-group"
                                                :error-messages="errors[0]"
                                                @change="setTripValue({ prop_1: 'arrivals', prop_2: index, prop_3: 'currency', value: $event })">
                                                <div class="view-trip-configurator__input-container__input-content">
                                                    <template v-for="(currency, i) in currenсies"> 
                                                        <BaseRadio
                                                            :key="'currency-' + i"
                                                            :value="currency.value"
                                                            :label="currency.label"
                                                            color="#E61E4D" />
                                                    </template>
                                                </div>
                                            </v-radio-group>
                                        </ValidationProvider>
                                    </div>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_arrivalPrice[lang] }}</div>
                                            <BaseTextField
                                                :value="arrival.price"
                                                :label="translates[langUrl].fieldTitle_arrivalPrice[lang]"
                                                type="Number"
                                                min="0"
                                                :error-messages="errors[0]"
                                                @input="setTripValue({ prop_1: 'arrivals', prop_2: index, prop_3: 'price', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_arrivalDiscount[lang] }}</div>
                                            <BaseTextField
                                                :value="arrival.discount"
                                                :label="translates[langUrl].fieldTitle_arrivalDiscount[lang]"
                                                type="Number"
                                                min="0"
                                                :error-messages="errors[0]"
                                                @input="setTripValue({ prop_1: 'arrivals', prop_2: index, prop_3: 'discount', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_arrivalDiscountBefore[lang] }}</div>
                                            <div class="view-trip-configurator__input-container__input-content__tour-type">
                                                <BaseDatePicker
                                                    :value="arrival.discountBefore"
                                                    :label="translates[langUrl].fieldTitle_arrivalDiscountBefore[lang]"
                                                    :error-messages="errors[0]"
                                                    @change="setTripValue({ prop_1: 'arrivals', prop_2: index, prop_3: 'discountBefore', value: $event })" />
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="view-trip-configurator__input-container">
                                            <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_arrivalGift[lang] }}</div>
                                            <BaseTextField
                                                :value="arrival.gift"
                                                :label="translates[langUrl].fieldTitle_arrivalGift[lang]"
                                                :error-messages="errors[0]"
                                                @input="setTripValue({ prop_1: 'arrivals', prop_2: index, prop_3: 'gift', value: $event })" />
                                        </div>
                                    </ValidationProvider>
                                    <!-- <div class="view-trip-configurator__info">
                                    <img src="../../assets/InformationCircle.svg" class="view-trip-configurator__info__icon" />
                                    <p class="view-trip-configurator__info__text"> При указании стоимости в $ или € трип будет оплачен в рублях по курсу ЦБ +2% на дату бронирования</p>
                                </div> -->
                                    <!-- <BaseCheckbox
                                        :label="translates[langUrl].checkbox_personalTour[lang]"
                                    /> -->
                                    <DeleteButton
                                        v-if="trip.arrivals.length > 1"
                                        @remove="removeArrival(index)">{{ translates[langUrl].button_deleteArrival[lang] }}
                                    </DeleteButton>
                                    <div v-if="trip.arrivals.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton @add="addArrival">{{ translates[langUrl].button_addArrival[lang] }}</AddButton>
                        </template>
                    </ValidationObserver>
                    <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                        <BaseButton
                            class="view-trip-configurator__step-buttons__button"
                            @click="back(15)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                        <div class="view-trip-configurator__desktop-buttons__rigth-container">
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(15)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                    </div>
                    <div v-else class="buttons">
                        <div class="view-trip-configurator__step-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(15)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(15)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                        <BaseButton 
                            class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                            @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                    </div>
                </v-tab-item>
                <v-tab-item value="step_16">
                    <ValidationObserver ref="validator_16" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_community[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.community"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('community')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.community">
                            <template v-for="(community, index) in trip.community">
                                <div :key="index" class="view-trip-configurator__form-card">
                                    <h3 v-if="!isDesktop" class="view-trip-configurator__block-name">{{ translates[langUrl].button_addPhoto[lang] }}</h3>
                                    <div class="view-trip-configurator__input-container">
                                        <div v-if="isDesktop" class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].button_addPhoto[lang] }}</div>
                                        <div class="img-container">
                                            <div
                                                v-if="community.photo && community.photo !== ''"
                                                :style="`background-image: url(${ imageSrc(community.photo) })`"
                                                class="img_slot__picture"
                                            >
                                                <div
                                                    class="img_slot__main-picture img_slot__main-picture__button" 
                                                    @click="setTripValue({ prop_1: 'community', prop_2: index, prop_3: 'photo', value: '' })"
                                                >{{ translates[langUrl].button_delete[lang] }}</div>
                                            </div>
                                            <ImgLoader
                                                v-else
                                                :text="translates[langUrl].button_addPicture[lang]"
                                                @change="setTripValue({ prop_1: 'community', prop_2: index, prop_3: 'photo', value: $event })"
                                            />
                                        </div>
                                    </div>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_firstname[lang] }}</div>
                                        <BaseTextField
                                            :value="community.firstname"
                                            :label="translates[langUrl].fieldTitle_firstname[lang]"
                                            :error-messages="errors[0]"
                                            :counter="120"
                                            @input="setTripValue({ prop_1: 'community', prop_2: index, prop_3: 'firstname', value: $event })" />
                                    </div>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_lastname[lang] }}</div>
                                        <BaseTextField
                                            :value="community.lastname"
                                            :label="translates[langUrl].fieldTitle_lastname[lang]"
                                            :error-messages="errors[0]"
                                            :counter="120"
                                            @input="setTripValue({ prop_1: 'community', prop_2: index, prop_3: 'lastname', value: $event })" />
                                    </div>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_review[lang] }}</div>
                                        <BaseTextArea
                                            :value="community.review"
                                            :label="translates[langUrl].fieldTitle_review[lang]"
                                            :error-messages="errors[0]"
                                            :counter="500"
                                            @input="setTripValue({ prop_1: 'community', prop_2: index, prop_3: 'review', value: $event })" />
                                    </div>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_social[lang] }}</div>
                                        <BaseTextField
                                            :value="community.social"
                                            :label="translates[langUrl].fieldTitle_social[lang]"
                                            :error-messages="errors[0]"
                                            :counter="120"
                                            @input="setTripValue({ prop_1: 'community', prop_2: index, prop_3: 'social', value: $event })" />
                                    </div>
                                    <DeleteButton
                                        v-if="trip.community.length > 1"
                                        @remove="removeTraveler(index)">{{ translates[langUrl].button_deleteTraveler[lang] }}
                                    </DeleteButton>
                                    <div v-if="trip.community.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton @add="addTraveler">{{ translates[langUrl].button_addTraveler[lang] }}</AddButton>
                        </template>
                    </ValidationObserver>
                    <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                        <BaseButton
                            class="view-trip-configurator__step-buttons__button"
                            @click="back(16)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                        <div class="view-trip-configurator__desktop-buttons__rigth-container">
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(16)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                    </div>
                    <div v-else class="buttons">
                        <div class="view-trip-configurator__step-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(16)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(16)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                        <BaseButton 
                            class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                            @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                    </div>
                </v-tab-item>
                <v-tab-item value="step_17">
                    <ValidationObserver ref="validator_17" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_questions[lang] }}</h1>
                            <v-switch
                                v-if="landing"
                                :input-value="trip.visibleBlocks.questions"
                                :label="translates[langUrl].button_showPage[lang]"
                                @change="switchVisibleBlock('questions')"
                            />
                        </div>
                        <template v-if="trip.visibleBlocks.questions">
                            <template v-for="(question, index) in trip.questions">
                                <div :key="index" class="view-trip-configurator__form-card">
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_question[lang] }} {{ index + 1 }}</div>
                                        <BaseTextField
                                            :value="question.question"
                                            :error-messages="errors[0]"
                                            :label="translates[langUrl].fieldTitle_question[lang]+ ' ' + index + 1"
                                            @input="setTripValue({ prop_1: 'questions', prop_2: index, prop_3: 'question', value: $event })" />
                                    </div>
                                    <div class="view-trip-configurator__input-container">
                                        <div class="view-trip-configurator__input-container__input-name">{{ translates[langUrl].fieldTitle_answer[lang] }}</div>
                                        <BaseTextArea
                                            :value="question.answer"
                                            :label="translates[langUrl].fieldTitle_answer[lang]"
                                            :error-messages="errors[0]"
                                            :counter="1020"
                                            @input="setTripValue({ prop_1: 'questions', prop_2: index, prop_3: 'answer', value: $event })" />
                                    </div>
                                    <DeleteButton
                                        v-if="trip.programs.length > 1"
                                        @remove="removeQuestion(index)">{{ translates[langUrl].button_deleteQuestion[lang] }}</DeleteButton>
                                    <div v-if="trip.programs.length > 1" class="view-trip-configurator__form-card__outline"></div>
                                </div>
                            </template>
                            <AddButton @add="addQuestion">{{ translates[langUrl].button_addQuestion[lang] }}</AddButton>
                        </template>
                    </ValidationObserver>
                    <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                        <BaseButton
                            class="view-trip-configurator__step-buttons__button"
                            @click="back(17)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                        <div class="view-trip-configurator__desktop-buttons__rigth-container">
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(17)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                    </div>
                    <div v-else class="buttons">
                        <div class="view-trip-configurator__step-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(17)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button button-fill"
                                @click="next(17)">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                        </div>
                        <BaseButton 
                            class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                            @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                    </div>
                </v-tab-item>
                <v-tab-item value="step_18">
                    <ValidationObserver ref="validator_18" slim>
                        <div class="view-trip-configurator__page-name__wrapper">
                            <h1 class="view-trip-configurator__page-name">{{ translates[langUrl].pageTitle_alerts[lang] }}</h1>
                        </div>
                        <div style="display: flex; flex-direction: column; margin-bottom: 10vw">
                            <div class="view-trip-configurator__telegram">{{ translates[langUrl].alerts_text[lang] }}</div>
                            <BaseButton class="button-fill" style="width: 300px" @click="botLink">{{ translates[langUrl].button_alerts[lang] }}</BaseButton>
                        </div>
                        <a ref="telegramUserBotLink" :href="telegramUserBotLink" target="_blank"></a>
                        <div v-if="isDesktop" class="view-trip-configurator__desktop-buttons">
                            <BaseButton
                                class="view-trip-configurator__step-buttons__button"
                                @click="back(17)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                            <div class="view-trip-configurator__desktop-buttons__rigth-container">
                                <BaseButton 
                                    v-if="checkUserProfile()"
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                                <BaseButton 
                                    v-else
                                    class="view-trip-configurator__step-buttons__button view-trip-configurator__desktop-buttons__save"
                                    @click="save()">{{ translates[langUrl].button_profile[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    style="width: 280px"
                                    @click="save(TRIP_STATUS.MODERATED)">{{ translates[langUrl].button_createPage[lang] }}</BaseButton>
                            </div>
                        </div>
                        <div v-else class="buttons">
                            <div class="view-trip-configurator__step-buttons">
                                <BaseButton
                                    class="view-trip-configurator__step-buttons__button"
                                    @click="back(18)">{{ translates[langUrl].button_back[lang] }}</BaseButton>
                                <BaseButton 
                                    class="view-trip-configurator__step-buttons__button button-fill"
                                    disabled
                                >{{ translates[langUrl].button_next[lang] }}</BaseButton>
                            </div>
                            <BaseButton 
                                v-if="checkUserProfile()"
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                            <BaseButton 
                                v-else
                                class="view-trip-configurator__step-buttons__button view-trip-configurator__save"
                                @click="save()">{{ translates[langUrl].button_profile[lang] }}</BaseButton>
                            <BaseButton 
                                class="view-trip-configurator__step-buttons__button moderate button-fill"
                                @click="save(TRIP_STATUS.MODERATED)">{{ translates[langUrl].button_createPage[lang] }}</BaseButton>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
            </v-tabs-items>
            <div v-if="isDesktop" class="view-trip-configurator__main-container__menu">
                <ul>
                    <li
                        v-if="tabs.items.indexOf(1) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_1' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(1)">{{ translates[langUrl].pageTitle_mainInfo[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(2) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_2' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(2)">{{ translates[langUrl].pageTitle_organizer[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(3) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_3' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(3)">{{ translates[langUrl].pageTitle_tripinNumber[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(4) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_4' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(4)">{{ translates[langUrl].pageTitle_map[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(5) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_5' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(5)">{{ translates[langUrl].pageTitle_tripCharacteristics[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(6) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_6' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(6)">{{ translates[langUrl].pageTitle_tripsPhotos[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(7) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_7' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(7)">{{ translates[langUrl].pageTitle_whatWillHappen[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(8) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_8' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(8)">{{ translates[langUrl].pageTitle_mainPlaces[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(9) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_9' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(9)">{{ translates[langUrl].pageTitle_benefits[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(10) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_10' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(10)">{{ translates[langUrl].pageTitle_programs[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(11) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_11' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(11)">{{ translates[langUrl].pageTitle_included[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(12) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_12' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(12)">{{ translates[langUrl].pageTitle_accommodations[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(13) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_13' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(13)">{{ translates[langUrl].pageTitle_transports[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(14) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_14' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(14)">{{ translates[langUrl].pageTitle_activities[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(15) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_15' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(15)">{{ translates[langUrl].pageTitle_arrivals[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(16) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_16' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(16)">{{ translates[langUrl].pageTitle_community[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(17) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_17' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(17)">{{ translates[langUrl].pageTitle_questions[lang] }}</li>
                    <li
                        v-if="tabs.items.indexOf(18) >= 0"
                        class="view-trip-configurator__main-container__menu__item"
                        :class="tabs.current === 'step_18' ? 'view-trip-configurator__main-container__menu__item_active' : ''"
                        @click="goTo(18)">{{ translates[langUrl].pageTitle_alerts[lang] }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';
    import { TRIP_STATUS, TRIP_TYPE, TRIP_TYPE_TITLE, TRIP_LEVEL, TRIP_LEVEL_TITLE, DIRECTION_TYPE, DIRECTION_TYPE_TITLE } from '../../vars';
    import { isMobile, imageSrc, checkUserProfile, translit } from '@/helpers';

    import BaseTextField from '../../components/common/BaseTextField';
    import BaseTextArea from '../../components/common/BaseTextArea'
    import BaseCheckbox from '../../components/common/BaseCheckbox';
    import BaseButton from '../../components/common/BaseButton';
    import BaseSelect from '../../components/common/BaseSelect';
    import BaseDatePicker from '../../components/common/BaseDatePicker';
    import BaseRadio from '../../components/common/BaseRadio';
    import BaseUploader from '../../components/common/BaseUploader';
    import Hashtags from '../../components/common/Hashtags';
    import Wishes from '../../components/common/Wishes';
    import ImgLoader from '../../components/common/ImgLoader';
    import AddButton from '../../components/common/AddButton';
    import DeleteButton from '../../components/common/DeleteButton';

    import Map from '../../components/common/configurator/Map';

    export default {
        name: 'Configurator',
        metaInfo() {
            return {
                title: this.translates[this.langUrl]? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseTextField,
            BaseTextArea,
            BaseCheckbox,
            BaseButton,
            BaseSelect,
            BaseDatePicker,
            BaseRadio,
            Hashtags,
            Wishes,
            ImgLoader,
            AddButton,
            DeleteButton,
            BaseUploader,
            yandexMap, 
            ymapMarker,

            Map
        },
        data: () => ({
            TRIP_STATUS,
            TRIP_TYPE,
            TRIP_TYPE_TITLE,
            TRIP_LEVEL,
            TRIP_LEVEL_TITLE,
            DIRECTION_TYPE,
            DIRECTION_TYPE_TITLE,
            imageSrc,
            tabs: {
                current: null,
                needValidate: 1,
                items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
                landingItems: [1, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
                marketplaceItems: {
                    [TRIP_TYPE.TOUR_LEADER]: [1, 2, 4, 5, 6, 8, 10, 11, 12, 13, 14, 15, 17, 18],
                    [TRIP_TYPE.HOST]: [1, 2, 4, 5, 6, 8, 10, 11, 12, 13, 14, 17, 18],
                    [TRIP_TYPE.TOUR_BLOGGER]: [1, 2, 4, 5, 6, 8, 10, 11, 12, 13, 14, 17, 18]
                }
            },
            conditionsCheckbox: [],
            groupAgeCheckbox: [],
            facilitiesCheckbox: {
                0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [], 12: [], 13: [], 14: [], 15: [], 16: [], 17: [], 18: [], 19: [], 20: []
            },
            currencyRadio: process.env.VUE_APP_DEFAULT_CURRENCY,
            currenсies: [
                { label: 'RUB', value: 'RUB' },
                { label: 'USD', value: 'USD' },
                { label: 'EUR', value: 'EUR' },
            ],
            validates: {
                step_1: null,
                step_2: null,
                step_3: null,
                step_4: null,
                step_5: null,
                step_6: null,
                step_7: null,
                step_8: null,
                step_9: null,
                step_10: null,
                step_11: null,
                step_12: null,
                step_13: null,
                step_14: null,
                step_15: null,
                step_16: null,
                step_17: null,
            },
            errors: {
                pictures: ''
            },
            deletedPictures: [],
            deletedPhotos: [],
            serverErrors: {
                code: ''
            },
            hostIstruction: false,
            isDesktop: false,
            marketplace: false,
            landing: false,
            langUrl: '/trip/create'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('dictionaries', ['types', 'transportTypes', 'accommodationsTypes', 'benefitsTypes', 'labels']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            typesSelect() {
                const types = [];
                for(const item in TRIP_TYPE) {
                    types.push({
                        value: TRIP_TYPE[item],
                        title: TRIP_TYPE_TITLE[TRIP_TYPE[item]]
                    })
                }
                return types;
            },
            directionTypesSelect() {
                const directionTypes = [];
                for(const item in DIRECTION_TYPE) {
                    directionTypes.push({
                        value: DIRECTION_TYPE[item],
                        title: DIRECTION_TYPE_TITLE[DIRECTION_TYPE[item]]
                    })
                }
                return directionTypes;
            },
            levels() {
                const levels = [];
                for(const level in TRIP_LEVEL_TITLE) {
                    levels.push({
                        title: TRIP_LEVEL_TITLE[level],
                        code: level
                    });
                }
                return levels;
            },
            telegramUserBotLink() {
                return `https://t.me/${process.env.VUE_APP_TELEGRAM_USER_BOT}`;
            }
        },
        async beforeRouteEnter(to, from, next) {
            store.commit('trips/CLEAR_ENTITY');
            if (to.params.id) {
                await store.dispatch('trips/get', { id: to.params.id });
            }
            next();
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        mounted() {
            this.$root.$emit('preloaderShow');
            this.conditionsCheckbox = this.trip ? this.trip.conditions : [];
            this.groupAgeCheckbox = this.trip ? this.trip.groupAge : [];
            this.trip.accommodations.forEach((element, index) => {
                this.facilitiesCheckbox[index] = element.facilities;
            });
            if(process.env.VUE_APP_SITE_TYPE === 'landing') {
                this.landing = true;
                this.tabs.items = this.tabs.landingItems;
            } else {
                this.marketplace = true;
                this.tabs.items = this.tabs.marketplaceItems[this.trip.type];
            }
            this.$root.$emit('preloaderHide');
        },
        methods: {
            onResize() {
                this.isDesktop = !isMobile();
            },
            setTripValue({ prop_1, prop_2, prop_3, value }) {
                store.commit('trips/SET_ENTITY_VALUE', { prop_1, prop_2, prop_3, value });
                if(prop_1 === 'name') {
                    if(!this.trip._id) {
                        store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'code', prop_2, prop_3, value: translit(value) });
                    }
                }
                if(prop_1 === 'type') {
                    this.tabs.items = this.tabs.marketplaceItems[this.trip.type];
                }
            },
            setTripPrice({ prop_1, prop_2, prop_3, value }) {
                store.commit('trips/SET_ENTITY_VALUE', { prop_1, prop_2, prop_3, value });
                if (this.trip.price < 2300) {
                    store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'level', value: TRIP_LEVEL.BEGINNER }); 
                } else if (this.trip.price < 3500) {
                    store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'level', value: TRIP_LEVEL.ADVANCED });
                } else if (this.trip.price >= 3500) {
                    store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'level', value: TRIP_LEVEL.EXPERT });
                }
            },
            switchVisibleBlock(block) {
                const value = !this.trip.visibleBlocks[block];
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'visibleBlocks', prop_2: block, value: value });
            },
            setTripPicture({ prop_1, prop_2, prop_3, prop_4, value }) {
                let pictures = [];
                if(prop_4) {
                    pictures = this.trip[prop_1][prop_2][prop_3][prop_4];
                }else if(prop_3) {
                    pictures = this.trip[prop_1][prop_2][prop_3];
                } else if(prop_2) {
                    pictures = this.trip[prop_1][prop_2];
                } else if(prop_1) {
                    pictures = this.trip[prop_1];
                }
                pictures.push(value);
                if(prop_4) {
                    this.trip[prop_1][prop_2][prop_3].picturesErrors = pictures.length === 0 ? 'Пожалуйста, добавьте хотя бы одно изображение' : null;
                } else if(prop_3) {
                    this.trip[prop_1][prop_2].picturesErrors = pictures.length === 0 ? 'Пожалуйста, добавьте хотя бы одно изображение' : null;
                } else if(prop_2) {
                    this.trip[prop_1].picturesErrors = pictures.length === 0 ? 'Пожалуйста, добавьте хотя бы одно изображение' : null;
                } else if(prop_1) {
                    this.trip.picturesErrors = pictures.length === 0 ? 'Пожалуйста, добавьте хотя бы одно изображение' : null;
                }
                store.commit('trips/SET_ENTITY_VALUE', { prop_1, prop_2, prop_3, prop_4, value: pictures });
            },
            removePicture({ prop_1, prop_2, prop_3, value }) {
                let pictures = [];
                if(prop_3) {
                    pictures = this.trip[prop_1][prop_2][prop_3];
                } else if(prop_2) {
                    pictures = this.trip[prop_1][prop_2];
                } else if(prop_1) {
                    pictures = this.trip[prop_1];
                }
                if(typeof prop_2 === 'undefined') {
                    if(this.trip.mainPictureIndex === value) {
                        store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'mainPictureIndex', value: 0 });
                    } else if (this.trip.mainPictureIndex > value) {
                        store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'mainPictureIndex', value: --this.trip.mainPictureIndex });
                    }
                }
                this.deletedPictures.push(pictures[value]);
                pictures.splice(value, 1);
                
                if(prop_3) {
                    this.trip[prop_1][prop_2].picturesErrors = pictures.length === 0 ? 'Пожалуйста, добавьте хотя бы одно изображение' : null;
                } else if(prop_2) {
                    this.trip[prop_1].picturesErrors = pictures.length === 0 ? 'Пожалуйста, добавьте хотя бы одно изображение' : null;
                } else if(prop_1) {
                    this.trip.picturesErrors = pictures.length === 0 ? 'Пожалуйста, добавьте хотя бы одно изображение' : null;
                }
                store.commit('trips/SET_ENTITY_VALUE', { prop_1, prop_2, prop_3, value: pictures });
                
            },
            removePhoto(index) {
                this.deletedPhotos.push(this.trip.photos[index]);
                this.trip.photos.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'photos', value: this.trip.photos });
            },
            addTripType() {
                let arr = this.trip.additionalTypes;
                if(!arr) arr = [];
                arr.push('');
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'additionalTypes', value: arr });
            },
            removeTripValue({ prop_1, value }) {
                let arr = this.trip[prop_1];
                arr.splice( value, 1 );
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'prop_1', value: arr });
            },
            addAccommodationsType() {
                let arr = this.trip.accommodationsTypes;
                if(!arr) arr = [];
                arr.push('');
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'accommodationsTypes', value: arr });
            },
            addTransportsType() {
                let arr = this.trip.transportsTypes;
                if(!arr) arr = [];
                arr.push('');
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'transportsTypes', value: arr });
            },
            addScript() {
                let arr = this.trip.scripts;
                if(!arr) arr = [];
                arr.push('');
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'scripts', value: arr });
            },
            removeScript(index) {
                let arr = this.trip.scripts;
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'scripts', value: arr });
            },
            addArrival() {
                let arr = this.trip.arrivals;
                if(!arr) arr = [];
                arr.push({
                    places: 0,
                    start: null,
                    finish: null,
                    discount: 0,
                    discountBefore: null,
                    price: 0,
                    currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'arrivals', value: arr });
            },
            removeArrival(index) {
                let arr = this.trip.arrivals;
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'arrivals', value: arr });
            },
            addProgram() {
                let arr = this.trip.programs;
                if(!arr) arr = [];
                arr.push({
                    title: null, //req
                    description: null, //req
                    pictures: [],
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'programs', value: arr });
            },
            removeProgram(index) {
                let arr = this.trip.programs;
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'programs', value: arr });
            },
            addIncluded() {
                let arr = this.trip.included;
                if(!arr) arr = [];
                arr.push({
                    title: null, //req
                    description: null, //req
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'included', value: arr });
            },
            removeIncluded(index) {
                let arr = this.trip.included;
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'included', value: arr });
            },
            addNotIncluded() {
                let arr = this.trip.notIncluded;
                if(!arr) arr = [];
                arr.push({
                    title: null, //req
                    description: null, //req
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'notIncluded', value: arr });
            },
            removeNotIncluded(index) {
                let arr = this.trip.notIncluded;
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'notIncluded', value: arr });
            },
            removeAdvantage(index) {
                let arr = this.trip.advantages;
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'advantages', value: arr });
            },
            addAccommodation() {
                let arr = this.trip.accommodations;
                if(!arr) arr = [];
                arr.push({
                    picture: null, //req
                    description: null, //req
                    title: null,
                    mainPictureIndex: 0,
                    type: null,
                    raiting: null,
                    bookingLink: null,
                    pictures: [],
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'accommodations', value: arr });
            },
            removeAccommodation(index) {
                let arr = this.trip.accommodations
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'accommodations', value: arr });
            },
            addTransport() {
                let arr = this.trip.transports;
                if(!arr) arr = [];
                arr.push({
                    picture: null, //req
                    description: null, //req
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'transports', value: arr });
            },
            removeTransport(index) {
                let arr = this.trip.transports
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'transports', value: arr });
            },
            addActivitiy() {
                let arr = this.trip.activities;
                if(!arr) arr = [];
                arr.push({
                    picture: null, //req
                    description: null, //req
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'activities', value: arr });
            },
            removeActivitiy(index) {
                let arr = this.trip.activities
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'activities', value: arr });
            },
            addTraveler() {
                let arr = this.trip.community;
                if(!arr) arr = [];
                arr.push({
                    firstname: null,
                    lastname: null,
                    photo: null,
                    review: null,
                    social: null,
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'community', value: arr });
            },
            removeTraveler(index) {
                let arr = this.trip.community
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'community', value: arr });
            },
            addMoment() {
                let arr = this.trip.moments;
                if(!arr) arr = [];
                arr.push({
                    title: '',
                    description: '',
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'moments', value: arr });
            },
            removeMoment(index) {
                let arr = this.trip.moments;
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'moments', value: arr });
            },
            addBenefit() {
                let arr = this.trip.benefits;
                if(!arr) arr = [];
                arr.push({
                    title: '',
                    type: '',
                    description: '',
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'benefits', value: arr });
            },
            removeBenefit(index) {
                let arr = this.trip.benefits;
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'benefits', value: arr });
            },
            addMainPlace() {
                let arr = this.trip.mainPlaces;
                if(!arr) arr = [];
                arr.push({
                    picture: '',
                    description: '',
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'mainPlaces', value: arr });
            },
            removeMainPlace(index) {
                let arr = this.trip.mainPlaces
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'mainPlaces', value: arr });
            },
            addQuestion() {
                let arr = this.trip.questions;
                if(!arr) arr = [];
                arr.push({
                    question: '',
                    answer: '',
                });
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'questions', value: arr });
            },
            removeQuestion(index) {
                let arr = this.trip.questions
                arr.splice(index, 1);
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'questions', value: arr });
            },
            duration(arr) {
                let program = arr.reduce((duration, currentValue) => {
                    return duration + currentValue.duration
                }, 0)
                return program
            },
            async validate(step) {
                const valid = await this.$refs['validator_' + this.tabs.needValidate].validate();
                this.validates['step_' + this.tabs.needValidate] = valid;
                if(this.tabs.needValidate === 1) {
                    this.errors.pictures = this.trip.pictures.length === 0 ? 'Пожалуйста, добавьте хотя бы одно изображение' : null;
                    this.validates['step_' + this.tabs.needValidate] = !!this.trip.pictures.length;
                }
                // if(this.tabs.needValidate === 4) {
                //     let notValid = false;
                //     for(const item of this.trip.programs) {
                //         item.picturesErrors = item.pictures.length === 0 ? 'Пожалуйста, добавьте хотя бы одно изображение' : null;
                //         notValid = !item.pictures.length ? true : notValid;
                //     }
                //     this.validates['step_' + this.tabs.needValidate] = !notValid;
                // }
                this.tabs.needValidate = step;
            },
            async goTo(item) {
                const response = await store.dispatch('trips/save');
                if(response.errors) {
                    for(const error of response.errors) {
                        this.serverErrors[error.field] = error.message;
                    }
                } else {
                    this.tabs.current = 'step_' + item;
                }
                this.validate(item);
            },
            checkUserProfile() {
                return checkUserProfile(this.user);
            },
            clearServerErrors() {
                this.serverErrors = {
                    code: ''
                };
            },
            async save(status = TRIP_STATUS.DRAFT) {
                this.$root.$emit('preloaderShow');
                for(let i=0; i < this.deletedPictures.length; i++) {
                    await store.dispatch('files/delete', { file: this.deletedPictures[i] });
                }
                for(let i=0; i < this.deletedPhotos.length; i++) {
                    if(this.deletedPhotos[i]._id) {
                        await store.dispatch('photos/delete', { id: this.deletedPhotos[i]._id });
                    }
                    await store.dispatch('files/delete', { file: this.deletedPhotos[i].picture });
                }

                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'status', value: status });

                if(!this.checkUserProfile()) {
                    store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'status', value: TRIP_STATUS.NEED_USER });
                }

                this.clearServerErrors();
                const response = await store.dispatch('trips/save');
                this.$root.$emit('preloaderHide');
                if(response.errors) {
                    for(const error of response.errors) {
                        this.serverErrors[error.field] = error.message;
                    }
                } else {
                    await this.$router.push({ name: 'user-trips' });
                }
            },
            async next(step) {
                if(this.tabs.items.indexOf(step) < this.tabs.items.length) {
                    this.validate(this.tabs.items[this.tabs.items.indexOf(step) + 1]);
                }
                this.$root.$emit('preloaderShow');
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'status', value: TRIP_STATUS.DRAFT });
                
                this.clearServerErrors();
                const response = await store.dispatch('trips/save');
                if(response.errors) {
                    for(const error of response.errors) {
                        this.serverErrors[error.field] = error.message;
                    }
                } else {
                    this.tabs.current = 'step_' + (this.tabs.items[this.tabs.items.indexOf(step) + 1]);
                }
                this.$root.$emit('preloaderHide');
            },
            async back(step) {
                if(step > 1) {
                    this.validate(this.tabs.items[this.tabs.items.indexOf(step) - 1])
                    this.tabs.current = 'step_' + (this.tabs.items[this.tabs.items.indexOf(step) - 1]);
                }
            },
            async routBack() {
                await this.$router.back();
            },
            downloadProgram(file) {
                if(this.user._id) {
                    return file ? `${ process.env.VUE_APP_STATIC_URL }/${ file.replace(/\\/g, '/') }` : '';
                }
            },
            botLink() {
                this.$refs.telegramUserBotLink.click()
            },
            showInstruction() {
                this.hostIstruction = !this.hostIstruction
            }
        },
    };
</script>

<style lang="scss">
.view-trip-configurator {
	padding-top: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;

    .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > 
    .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) 
    .v-slide-group__prev {
        display: none;
    }
    .v-tabs-slider-wrapper {
        display: none;
    }
    .theme--light.v-tabs > .v-tabs-bar {
        max-height: 4px;
        @media all and (min-width: 768px) {
            max-height: 6px;
        }
    }
    .v-slide-group__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .v-tab {
        min-width: 6%;
        &.landing {
            min-width: 5%;
        }
        height: 4px;
        background: #F2F2F2;
        border-radius: 30px;
        padding: 0px;
        @media all and (min-width: 768px) {
            height: 6px;
        }
        &:first-of-type {
            margin-left: 0;
        }
        &:last-of-type {
            margin-right: 0;
        }
        .validate-check {
            min-width: 100%;
            height: 4px;
            border-radius: 30px;
            @media all and (min-width: 768px) {
                height: 6px;
            }
            &.validate-true {
                background: green;
            }
            &.validate-false {
                background: #ff5252;
            }
        }
        &.v-tab--active .validate-check {
            background: #DA0A63;
        }
    }
    .file__pattern {
        position: relative;
        min-width: 140px;
        width: 140px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px dashed #dbdae0;
        border-radius: 12px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        margin: 10px 0;

        &__link {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
    &__tabs-outline {
        border-bottom: 1px solid #E5E6E9;
        margin: 32px 0 16px;
    }
    &__page-name {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        color: #3A4256;
        
        &__wrapper {
            display: flex; 
            flex-direction: column; 
            margin: 24px 0;

            @media all and (min-width: 768px) {
                flex-direction: row; 
                align-items: center; 
                justify-content: space-between;
            }
        }
    }
    &__main-container {
        display: flex;
        flex-direction: row;

        &__items {
            width: 1042px;
            padding: 20px;
            background: #FFFFFF;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 12px;
            @media all and (min-width: 768px) {
                padding: 32px;
            }
        }

        &__menu{
            width: 325px;
            height: fit-content;
            margin-left: 32px;
            padding: 20px 4px;
            background: #F7F9FA;
            border: 1px solid #FFFFFF;
            box-sizing: border-box;
            border-radius: 12px;
            overflow: hidden;

            &__item {
                padding: 10px;
                list-style-type: none;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #525B77;
                transition-duration: 1s;
                cursor: pointer;

                &_active {
                    color: #fff;
                    background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
                    border-radius: 12px 0 0 12px;
                    // opacity: .8;
                    transition-duration: 1s;
                }
            }
        }
    }

    &__helper {
        position: relative;
        max-width: 1500px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 40px;
        font-family: 'Inter';
        border-radius: 20px;
        &__picture {
            width: 80%;
            height: 80%;
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 27px;
            color: #000000;
            margin: 0 auto 12px;
            text-align: center;
        }
        &__text {
            font-weight: 600;
            font-size: 22px;
            line-height: 140%;
            color: #000000;
            text-align: center;
            letter-spacing: -0.02em;
            margin: 20px auto 5px;
            width: 100%;
            text-align: left;
            &__accent {
                font-weight: 500;
                color: black;
            }
        }
    }

    &__warning {
        padding: 40px;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
    }
    &__step-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 4px;
        width: 100%;

        &__button {
            width: 159px;
            height: 48px;
            margin-bottom: 12px;
            background: #FFFFFF;
            color: #DA0A63;

            &.moderate {
                min-width: 100% !important;
                @media all and (min-width: 768px) {
                    width: 280px;
                }
            }
        }
    }
    &__save {
        background: linear-gradient(270deg, rgba(230, 30, 77, 0.1) 0%, rgba(230, 30, 77, 0.1) 100%), #FFFFFF;
        width: 100%;
        border: none !important;
    }
    &__desktop-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__rigth-container {
            display: flex;
            flex-direction: row;
            .v-btn {
                margin-left: 20px;
            }
        }
        &__save {
            width: 230px;
            background: linear-gradient(270deg, rgba(230, 30, 77, 0.1) 0%, rgba(230, 30, 77, 0.1) 100%), #FFFFFF;
            border: none !important;
        }
    }
    &__addTripType-button {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 56px;
        border: 1px dashed #E5E6E9;
        border-radius: 10px;
        box-sizing: border-box;
        align-items: center;
        margin-bottom: 16px;

        &:hover {
            cursor: pointer;
        }
        @media all and (min-width: 768px) {
            margin-left: 225px;
            width: calc(100% - 225px);
        }
        &__icon {
            width: 14px;
            height: 14px;
            margin: 0 13px 0 20px;
        }
        &__text {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #A9AEB2;
        }
    }
    &__tour-type__delete-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 55px;
        margin-left: 5px;

        &__delete-icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
    &__block-name {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155;
        padding: 8px 0 12px;
    }
    &__map-direction {
        min-width: 400px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155;
        padding: 8px 0 12px;

        @media all and (max-width: 768px) {
            min-width: 85vw;
        }
    }
    &__input-container{
        display: flex;
        width: 100%;
        min-height: 56px;

        &__line {
            display: flex;
            flex-direction: row;
        }
        &__input-name{
            display: none;
            @media all and (min-width: 768px) {
                display: flex;
                min-width: 185px;
                max-width: 185px;
                margin-right: 40px;
                color: #65676E;
                padding-top: 18px;
            }
        }
        &__input-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            @media all and (min-width: 768px) {
                flex-direction: row;
            }
            &__tour-type {
                width: 100%;
                flex-direction: column;
            }
            &__list {
                width: 100%;
                flex-direction: column;

                > div {
                    padding-bottom: 20px;    
                }
                .v-textarea.base-text-area {
                    margin-bottom: 5px !important;
                }
                .delete-button__container {
                    margin-top: 5px;
                }
            }
        }
        &__info {
            padding: 18px 15px 0;
            cursor: pointer;
            &__icon {
                width: 20px;
                height: 20px;
            }
        }
    }
    &__form-card {
    padding-top: 28px;
        &:first-of-type {
            padding-top: 0;
        }
        &__delete-button-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        &__outline {
            border-bottom: 0.5px solid #E5E6E9;
            padding: 28px 0 4px;
            margin-bottom: 20px;
        }
    }
    &__radio-group {
        margin-top: 0;
        padding-bottom: 8px;
        @media all and (min-width: 768px) {
            margin-top: 10px;
            &.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
            margin-bottom: 0px;
            }
        }
        
    }
    &__span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        text-align: right;
        letter-spacing: -0.02em;
        color: #A2A5AE;
        margin-left: 3px;
    }
    &__info {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        @media all and (min-width: 768px) {
            margin-left: 225px;
        }

        &__icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
        &__text {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #A2A5AE;
            max-width: 79%;
        }
        p {
            margin: 0;
        }
    }
    &__telegram {
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        color: #3A4256;
        text-align: center;
        margin: 10vw auto 40px;
        max-width: 800px;
    }
    .buttons {
        display: flex;
        flex-direction: column;
    }
    .img-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
        gap: 10px;
        padding-bottom: 10px;
    }
    .img_slot {
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        &__picture {
            width: 175px;
            height: 166px;
            background-size: cover;
            background-position: center;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
        }
        &__main-picture {
            position: absolute;
            bottom: 8px;
            right: 8px;
            background: rgba(12, 12, 12, 0.2);
            border-radius: 4px;
            padding: 4px 10px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 140%;
            color: #FFF;

            &__button {
                cursor: pointer;
            }
        }
        &__picture-menu {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
}
.days {
	display: flex;
}
</style>